@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  color: #d4296b;
}

p,
li {
  /* font-family: "Nanum Myeongjo", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.2px; */
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;

}

p {
  text-align: justify;
}

h2 {
  color: #d4296b;
  text-align: center;
  padding-top: 4%;

  text-transform: uppercase;

}

/* layout card styles */
.sections1 {
  display: none;
}

.sections {
  background-image: url('../public/image\ \(44\).png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #d4296b;
  backdrop-filter: blur(2px);
}

.wrapper {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */
.wrapper .image {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  border-radius: 15px 15px 15px 15px;
}

.image {
  position: relative
}

img {
  display: block;
  margin: auto;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: 0.6s;
  border-radius: 5px;
}

.overlay:hover {
  opacity: 1;
}

.overlay a {
  text-decoration: none;
  color: #009fa3;
  font-weight: bold;
  margin-bottom: 15px;
}

.overlay>* {
  transform: translateY(25px);
  transition: transform 0.6s;
}

.overlay:hover>* {
  transform: translateY(0px);

}

.imageUnderText {
  margin-top: -7%;
}

.imageUnderText a {
  color: white;
  transition: all 1s ease-in-out;
}

.imageUnderText a:hover {
  color: black;
}

@media (max-width:699px) {
  .sections {
    display: none;
  }

  .sections1 {
    display: block;
  }

  .sections1 {
    background-image: url('../public/image\ \(44\).png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #d4296b;
    backdrop-filter: blur(2px);
    height: 700px;
  }

  .imageUnderText {}

  .wrapper {
    margin-top: -10%;
  }
}

.layoutImage {
  width: 45%;
  margin-left: 10%;
}

.iconsection .iconmenu {
  color: #d4296b;
  margin-left: 5%;
  margin-right: 5%;
  font-size: x-large;
  margin-top: 1%;
}

.iconmenu {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #009fa3;
  height: 50px;
  width: 50px;
  border-radius: 50px 50px 50px 50px;
  display: inline-block;
  margin-top: 0px;
  transition: all 1s ease-in-out;
}

.iconmenu:hover {
  background-color: #d4296b;
}

.iconmenu .icons {
  margin-top: 10%;
  color: white;
}

@media (max-width:699px) {
  .iconsection {
    margin-top: 3%;
  }

  .layoutImage {
    width: 40%;
    margin-left: 30%;
  }

  .iconsection .icons {
    color: white;
    margin-left: 12%;
    margin-right: 12%;
    font-size: 15;
    margin-top: -5%;
  }

  .iconmenu {
    margin-left: 10px;
    margin-right: 10px;
    background-color: #d4296b;
    height: 40px;
    width: 40px;
    border-radius: 50px 50px 50px 50px;
    display: inline-block;
    margin-top: 0px;
    transition: all 1s ease-in-out;
  }
}

/* 
Home page styles */

.topmargin {
  margin-top: 6.7%;
}

@media (max-width:699px) {
  .topmargin {
    margin-top: 25%;
  }
}

.footer {
  background-color: #d4296b;
  padding: 5%;
  border-radius: 5px 5px 5px 5px;
  color: white;
  margin-top: 5%;
}

.footer img {
  width: 100%;
  margin-bottom: 5%;
}

.col-md-6 h6 {
  margin-bottom: 5%;
  font-size: x-large;
}

.col-md-6 ul {
  list-style-type: none;
}

.col-md-3 h6 {
  margin-bottom: 5%;
  font-size: x-large;
  text-align: center;
}

/* Home Cards treatments */
.Homecards {
  width: 18rem;
  height: 100%;
}

@media (max-width:699px) {
  .Homecards {
    width: 100%;
    height: 100%;
    margin-top: 2%;
  }
}

.gradient-custom {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  /* background-image: url('../public/image\ \(44\).png');
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-color: #d4296b;
   backdrop-filter: blur(2px);  */
  /* background: radial-gradient(50% 123.47% at 50% 50%, #d4296b 0%, #d4296b 100%),
    linear-gradient(121.28deg, #d4296b 0%, #d4296b 100%),
    linear-gradient(360deg, #d4296b 0%, #d4296b 100%),
    radial-gradient(100% 164.72% at 100% 100%, #d4296b 0%, #d4296b 100%),
    radial-gradient(100% 148.07% at 0% 0%, #009fa3 0%, #009fa3 100%);
  background-blend-mode: screen, color-dodge, overlay, difference, normal; */
}

.carousel-indicators {
  border-radius: 10%;
  width: 10px;
  height: 10px;
}

.treatmentImages {
  width: 100%;
  margin-bottom: 2%;
  margin-top: 0%;
  border-radius: 5px;
}

.services img {
  width: 70%;

  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  transition: all 0.6s ease-in-out;
}

.services img:hover {

  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border: 2px solid #d4296b;
}

.footerText {
  text-align: center;
  width: 140%;
  margin-left: -25%;
}

@media (max-width:699px) {
  .footerText {
    text-align: center;
    width: 100%;
    margin-left: 0%;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* Blogs styles */

#BlogCard {
  transition: all 0.6s ease-in-out;
  background-color: #f4dce5;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;  */
}

#BlogCard:hover {
  transform: 2s;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


#BlogCard img {
  width: 100%;
  align-items: center;
}

#BlogCard h6 {
  transition: all 0.6s ease-in-out;
}

#BlogCard h6:hover {
  color: #d4296b;
}

#BLOGiMAGE {
  margin-left: auto;
  margin-right: auto;
  transition: all 0.6s ease-in-out;
  padding: 3%;
}

#BLOGiMAGE #image {
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px 15px 15px 15px;

}

#BLOGiMAGE:hover {
  transform: scale(1.03);
  
}
#blogContent h4{
  color: #009fa3;
}
#blogContent p{
  text-align: justify;
}