.bg-body-tertiary img{
  width: 32%;
  margin-top: -1%;
  margin-bottom: -1%;
}
@media (max-width:699px) {
  .bg-body-tertiary img{
    width: 84%;
    margin-left: -5%;
    margin-top: -2%;
  }
}
.navbar .ms-auto .items {
  color: #d4296b;

  padding: 15px 25px 10px 25px;
  transition: 0.6s all ease-in-out;
}

.navbar .ms-auto .items:hover {
  color: black;

}

.navbar #collapsible-nav-dropdown {
  color: #d4296b;
  transition: 0.6s all ease-in-out;
  padding: 0px 5px 0px 5px;
}



.navbar #responsive-navbar-nav .items {
  color: #d4296b;
  padding: 15px 25px 10px 25px;
}

.navbar #responsive-navbar-nav .items .headerWhatsapp {
  font-size: 23px;
  color: rgb(9, 175, 9);
}


.dentalrow {
  background-color: white;
  width: 500%;
  margin-top: -6%;
  margin-left: -200%;
  height: 100%;
}

.dentalrow .col-md-4 {
  padding: 5%;
}

.dentalrow .col-md-4 .dropdownitem {
  padding: 5%;
}

@media (max-width:799px) {
  .dentalrow {
    width: 0%;
    margin-left: 0%;

  }
}

.nav-link {
  font-size: 0.95rem;
}

#basic-nav-dropdown {
  font-size: 0.95rem;
}

#seeMoreLink {
  color: #607d8b;
}

#seeMoreLink:hover {
  color: black;
  font-weight: 400;
}

.dropdown-menu {
  padding: 1em;
}

.dropdown-item:hover {
  outline: none;
  box-shadow: none;
  background: none;
}

.dropdown-item a:hover {
  color: black;
}

.dropdown-item {
  height: 2em;
  padding-left: 0em;
}

/* .dropdown-header {
  font-weight: 600;
  padding-left: 0em;
}

@media only screen and (min-width: 600px) {
  .eventsNav {
    width: 22em;
    padding-bottom: 1em;
  }
} */

/* mega menu styles */

#dropdown-basic {
  background-color: white;
  color: #d4296b;
  border: none;
  margin-top: 8%;
  outline: none;
 
  

}
#dropdown-basic1 {
  background-color: white;
  color: #d4296b;
  border: none;
  margin-top: 8%;
  outline: none;
  
}

#dropdown-basic:focus,
#dropdown-basic:active {
  outline: none !important;
  box-shadow: none;
}
#dropdown-basic1:focus,
#dropdown-basic1:active {
  outline: none !important;
  box-shadow: none;
}


.mega-menu {
  width: 800px;
  /* Set the width of the mega menu */
  margin-left: -350%;
  
  
  margin-top: -25px;
}

.mega-menu1{
  
  
  
}
.mega-menu1 .galleryitems{
  text-align: center;
  color: black;
  transition: all 0.6s ease-in-out;
}
.mega-menu1 .galleryitems:hover{
  color: #d4296b;
}

.galleryitems1{
  text-align: center;
  color: black;
  transition: all 0.6s ease-in-out;
}
.galleryitems1:hover{
  color: #d4296b;
}

.mega-menu-columns {
  display: flex;
  color: black;
}

.mega-menu-column {
  flex: 1;
  padding: 20px;
}
.mega-menu-column .megamenuitems{
  color: black;
  transition: all 0.6s ease-in-out;
}
.mega-menu-column .megamenuitems:hover{
  color: #d4296b;
}

.mega-menu-column h5 {
  margin-bottom: 10px;

}

@media (max-width:699px) {
  #dropdown-basic{
    background-color: #d4296b;
    color: white;
    border: none;
    margin-top: 3%;
    margin-left: 3.5%;
    outline: none;
    margin-bottom: -2px;
  }

  .mega-menu {
    width: 69%;
    /* Set the width of the mega menu */
    margin-left:5%;
    background-color: #d4296b;
    margin-top: 0px;
    column-width: 100px;
    color: white;
    border: none;
    margin-bottom: -12%;
  }

  .mega-menu-columns {
    display: block;
    background-color: white;
    margin-left: -12%;
    margin-top: 28%;
    width: fit-content;
    
  }
  .mega-menu-column {
    flex: 1;
    padding: 20px;
    margin-top: -24%;
    margin-bottom: 10%;
  }

  #responsive-navbar-nav {
    background-color: #d4296b;
    margin-top: 15px;
  }

  .navbar .ms-auto .items {
    color: white;
  
    padding: 4px;
    transition: 0.6s all ease-in-out;
  }
  
  .navbar .ms-auto .items:hover {
    color: black;
  
  }
  
  .navbar #collapsible-nav-dropdown {
    color: white;
    transition: 0.6s all ease-in-out;
    padding: 0px 5px 0px 5px;
  }
  
  .navbar #collapsible-nav-dropdown:hover {
    color: black;
  
  }
  
  .navbar #responsive-navbar-nav .items {
    color: white;
    padding: 18px 10px 7px 26px;
  }
  #dropdown-basic1{
    background-color: #d4296b;
    color: white;
    margin-top: 2%;
    margin-left: 3.5%;
    margin-bottom: -2%;
  }
  .mega-menu1{
    margin-left: 5%;
    
   border: none;
  padding: -4%;
  margin-top: -100%;
  width: 50%;
  
  }
  .mega-menu1 .galleryitems1{
    text-align: center;
  
    margin-top: -5%;
    
  }
  .mega-menu1 .galleryitems{
   
  }
  .mega-menu-column .megamenuitems{
     padding:5%;
  }

}