/* 

nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 70px;
    
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;
  }
  nav .navbar{
    height: 100%;
    max-width: 1250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
   
    padding: 0 50px;
  }
  nav .navbar img{
    width: 25%;
  }
  nav .navbar .nav-links{
    line-height: 70px;
    height: 100%;
  }
  nav .navbar .nav-links .links{
    display: flex;
    list-style-type: none;
  }
  nav .navbar .nav-links .links .main .mainlink{
    
    margin: 40px;
    text-decoration: none;
    color: #d4296b;
    transition: 0.1s all ease-in-out;
  }
  nav .navbar .nav-links .links .main .mainlink:hover{ 
    color: #45061e;
  }
  nav .navbar .nav-links .links .main .mainlink .menu li .p-2{
      text-decoration: none;
      color: white;
      transition: 0.1s all ease-in-out;
  }
  nav .navbar .nav-links .links .main .mainlink .menu li .p-2:hover{
    color: #45061e;
  }
  nav .navbar .nav-links .links .menu{
    background-color: #009fa3;
    position: absolute;
    list-style-type: none;
    width: 17%;
    margin:0% 1% 2% 1%;
    margin-left: -3%;
  }
  
  nav .navbar .nav-links .links .menu li{
      margin-bottom: -14%;
      margin-top: -10%;
      margin-left: 0%;
      color: white;
      transition: 0.6s all ease-in-out;
  }
  
  nav .navbar .nav-links .links .menu li:hover{
    color: #45061e;
  }
  nav .navbar .nav-links .links .menu li a{
    padding-top: 20%;
    align-items: center;
    text-align: center;
  }
  
  nav .navbar .nav-links .links .menu .sub-menu1 {
    margin-left: 85%;
    list-style-type: none;
    position: absolute;
    background-color: #d4296b;
    margin-top: -20%;
    width: 100%;
  }
  nav .navbar .nav-links .links .menu .sub-menu1 li .p-2{
    text-decoration: none;
    margin-left: -10%;
    color: white;
  }
  
  
  nav .navbar .nav-links .links .menu .sub-menu2{
    margin-left: 85%;
    list-style-type: none;
    position: absolute;
    background-color: #d4296b;
    margin-top: -30%;
    width: 100%;
  }
  nav .navbar .nav-links .links .menu .sub-menu2 li .p-2{
    text-decoration: none;
    margin-left: -10%;
    color: white;
  }
  nav .navbar .nav-links .links .menu .sub-menu3 {
    margin-left: 85%;
    list-style-type: none;
    position: absolute;
    background-color: #d4296b;
    margin-top: -30%;
    width: 100%;
  }
  nav .navbar .nav-links .links .menu .sub-menu3 li .p-2{
    text-decoration: none;
    margin-left: -10%;
    color: white;
  }
  nav .navbar .nav-links .links .menu .sub-menu4 {
    margin-left: 85%;
    list-style-type: none;
    position: absolute;
    background-color: #d4296b;
    margin-top: -30%;
    width: 100%;
  }
  nav .navbar .nav-links .links .menu .sub-menu4 li .p-2{
    text-decoration: none;
    margin-left: -10%;
    color: white;
  }
  nav .navbar .nav-links .links .menu .sub-menu5 {
    margin-left: 85%;
    list-style-type: none;
    position: absolute;
    background-color:#d4296b;
    margin-top: -40%;
    width: 100%;
  }
  nav .navbar .nav-links .links .menu .sub-menu5 li .p-2{
    text-decoration: none;
    margin-left: -10%;
    color: white;
  }
  nav .navbar .nav-links .links .menu .sub-menu6 {
    margin-left: 70%;
    list-style-type: none;
    position: absolute;
    background-color: #d4296b;
    margin-top: -70%;
    width: 100%;
  }
  nav .navbar .nav-links .links .menu .sub-menu6 li .p-2{
    text-decoration: none;
    margin-left: -10%;
    color: white;
  }
  
  .menubar{
    display: none;
  }
  
  @media (max-width:799px) {
    .menubar{
      display: block;
      position: absolute;
      justify-content: end;
      margin-left: 70%;
      
    }
    nav .navbar img{
      width: 70%;
      margin-left: -10%;
    }
    nav .navbar .nav-links{
      display: none;
    }
    nav .navbar img{
      position: absolute;
    }
    nav .navbar .menunav .links{
      list-style-type: none;
      margin-top: 10%;
      margin-left: -20%;
      background-color: #d4296b;
      width: 110%;
      height: auto;
      position: absolute;
    }
    nav .navbar .menunav .links .main{
      margin-top: -3%;
      padding: 5%;
    }
    nav .navbar .menunav .links .main .mainlink{
      text-decoration: none;
      color: white;
      
    }
    
  
    nav .navbar .menunav .links .menu{
      list-style-type: none;
      width: 20%;
      
    }
    
    nav .navbar .menunav .links .menu li{
        margin-bottom: -10%;
        margin-left: 20%;
        color: white;
        padding: 20%;
    }
    nav .navbar .menunav .links .menu li a{
      
      align-items: center;
      text-align: center;
    }
    
    nav .navbar .menunav .links .menu .sub-menu1 {     
      list-style-type: none;
      background-color: #d4296b;
      width: 100%;
      
    }
  
    
    nav .navbar .menunav .links .menu .sub-menu2{      
      list-style-type: none;     
      width: 100%;
    }
    nav .navbar .menunav .links .menu .sub-menu3 {      
      list-style-type: none;      
      background-color: #d4296b;    
      width: 100%;
    }
    nav .navbar .menunav .links .menu .sub-menu4 {     
      list-style-type: none;  
      
      width: 100%;
    }
    nav .navbar .menunav .links .menu .sub-menu5 {
      list-style-type: none;     
      width: 100%;
    }
    nav .navbar .menunav .links .menu .sub-menu6 {   
      list-style-type: none;     
      width: 100%;
      margin-bottom: -20px;
    }
    nav .navbar .menunav .links .menu .sub-menu1 li .p-2{
      text-decoration: none;
      margin-left: -10%;
      color: white;
    }
    nav .navbar .menunav .links .menu .sub-menu2 li .p-2{
      text-decoration: none;
      margin-left: -10%;
      color: white;
    }
    nav .navbar .menunav .links .menu .sub-menu3 li .p-2{
      text-decoration: none;
      margin-left: -10%;
      color: white;
    }
    nav .navbar .menunav .links .menu .sub-menu4 li .p-2{
      text-decoration: none;
      margin-left: -10%;
      color: white;
    }
    nav .navbar .menunav .links .menu .sub-menu5 li .p-2{
      text-decoration: none;
      margin-left: -10%;
      color: white;
    }
    nav .navbar .menunav .links .menu .sub-menu6 li .p-2{
      text-decoration: none;
      margin-left: -10%;
      color: white;
      
      
    }
    nav .navbar .menunav .links .menu .sub-menu1 li{
      padding: 7px;
    }
    nav .navbar .menunav .links .menu .sub-menu2 li{
      padding: 7px;
    }
    nav .navbar .menunav .links .menu .sub-menu3 li{
      padding: 7px;
    }
    nav .navbar .menunav .links .menu .sub-menu4 li{
      padding: 7px;
    }
    nav .navbar .menunav .links .menu .sub-menu5 li{
      padding: 7px;
    }
    nav .navbar .menunav .links .menu .sub-menu6 li{
      padding: 7px;
    }
    nav .navbar .menunav .links .main .mainlink .menu li .p-2{
      text-decoration: none;
      color: white;
      transition: 0.1s all ease-in-out;
  }
  nav .navbar .menunav .links .main .mainlink .menu li .p-2:hover{
    color: #45061e;
  }
  } */

  .bg-body-tertiary .image1{
    margin-top: 0%;
    margin-bottom: 0%;
    height: 75px;
    width: 250px;
    border-radius: 5px;
  }

.navbar .ms-auto .navitems {

  color: #d4296b;

  padding: 15px 25px 10px 25px;
  transition: 0.6s all ease-in-out;
}

.navbar .ms-auto #collasible-nav-dropdown {

  color: #d4296b;

  padding: 15px 25px 10px 25px;
  transition: 0.6s all ease-in-out;
}

.navbar #responsive-navbar-nav .navitems {

  color: #d4296b;

  padding: 15px 25px 10px 25px;
  transition: 0.6s all ease-in-out;
}



#collasible-nav-dropdown .dropDown .dropitem {
  text-align: center;
  transition: all 0.6s ease-in-out;
  color: #d4296b;
}

.navbar .ms-auto #collasible-nav-dropdown :active,
.navbar .ms-auto #collasible-nav-dropdown:focus-visible {
  background-color: #d4296b;
}

.menuitem5 {

  margin-left: -70%;

}

.menuitem5 .dropdownbtn {
  color: #d4296b;
  box-shadow: none;
}

.menuitem5 .dropdownbtn .dropitem:hover {
  color: #d4296b;
}

#dropdown-basic2:focus,
#dropdown-basic2:active {
  outline: none !important;
  box-shadow: none;
}

#dropdown-basic5:focus,
#dropdown-basic5:active {
  outline: none !important;
  box-shadow: none;
}

#dropdown-basic5 {
  margin-top: 8%;
  border: none;
  color: #d4296b;

}

#dropdown-basic3:focus,
#dropdown-basic3:active {
  color: black;
  background-color: white;
  border: 1px solid #d4296b;
  border-radius: 4px;
  margin: 5%;
  padding: 5%;
}

#btnchange:focus,
#btnchange:active {
  outline: none !important;
  box-shadow: none;
}

#btnchange{
  color: #d4296b;
  margin-top: 4%;
}

.dropdownbtn2 {
  background-color: white;
  border: 1px solid #d4296b;
  border-radius: 4px;
  margin: 5%;
  padding: 5%;
}

.mega-menu5 {

  margin-bottom: -5%;

  padding: -4%;
  margin-top: -100%;

}

.mega-menu5 .galleryitemed {
  text-align: center;
  color: black;
  transition: all 0.6s ease-in-out;
}

.mega-menu5 .galleryitemed:hover {
  color: #d4296b;
}

@media (max-width:699px) {
  .menuitem5 {
    margin-left: 0;
  }

  #dropdown-basic5 {
    margin-top: 2%;
    margin-left: 3.5%;
    border: none;
    color: white;
  }

  .navbar #responsive-navbar-nav .navitems {
    color: white;
  }

  .navbar #responsive-navbar-nav #collasible-nav-dropdown {
    color: white;
    margin-top: 2%;
  }

  .mega-menu5 {
    width: 50%;
    margin-left: 5%;
  }
  #btnchange{
    color: white;
    border: 1px solid white;
    margin-top: 0%;
    margin-bottom: 5%;
   margin-left: 25%;
   
  }
  .bg-body-tertiary .image1{
    width: 70%;
  }
}